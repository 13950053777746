import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'
import * as Sentry from '@sentry/browser'

import store from 'lib/store'
import { getRequest } from 'lib/api/api'
import { TOKENS_STORAGE_KEY } from 'lib/api/constants'
import { mergeOrReplaceEntities } from 'lib/records/workflows/entities'
import users from 'lib/records/modules/users'
import { getRecord } from 'lib/records/workflows/getRecord'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import { meSelector } from 'domains/authentication/selectors/me'
import trackSessionAnalytics, {
  setUserProperties,
} from 'domains/application/utils/trackSessionAnalytics'
import {
  ORG_TYPE_TO_ROLE_MAP,
  ROLE_TYPES,
} from 'domains/authentication/constants/roles'
import {
  myOrganizationPropSelector,
  myOrganizationIdSelector,
} from 'domains/authentication/selectors/organizations'
import { setApplicationProperties } from 'domains/application/ducks/application'
import IUser from 'domains/users/models/IUser'
import { USER_PREFERENCES_RECORD } from 'lib/records/modules/userPreferences'
import {
  listenForNotifications,
  cancelNotificationListener,
} from 'domains/notifications/workflows/listenForNotifications'
import { myNotificationPreferenceSelector } from 'domains/notifications/selectors/notificationSelectors'
import devUser from 'domains/authentication/constants/devUser'

export const getUserProperties = (user: any, org: any = {}) => ({
  id: R.prop('id', user),
  firstName: R.prop('firstName', user),
  lastName: R.prop('lastName', user),
  jobTitle: R.prop('jobTitle', user),
  environment: utils.host.getHostname(),
  orgId: R.prop('id', org),
  orgName: R.prop('name', org),
  orgType: R.prop('type', org),
  orgReferrerId: R.prop('referrerId', org),
  orgMembershipLevel: R.prop('membershipLevel', org),
  orgAdmin: R.propOr(false, 'admin', user),
  ims: R.prop('ims', user),
})

export const whoAmI = async (): Promise<boolean | ROLE_TYPES> => {
  cancelNotificationListener()
  const tokens = utils.localStorage.getLocalItem(TOKENS_STORAGE_KEY)
  const isTestMode =
    utils.env.getEnvVariable('TEST', process.env.TEST) === 'true' || false

  if (!tokens) {
    return false
  }
  let me: boolean | IUser = false
  let orgId: string | null = ''

  if (!isTestMode && process.env.NODE_ENV === 'development') {
    const meEntity = await mergeOrReplaceEntities(false, users, devUser, false)
    me = meEntity
    store.dispatch(setApplicationProperties({ me }))
    orgId = devUser.organization.id
  } else {
    const response = await getRequest('login')

    if (response.success) {
      const meEntity = await mergeOrReplaceEntities(
        false,
        users,
        response.data,
        false,
      )
      me = meEntity
      store.dispatch(setApplicationProperties({ me }))
    }

    orgId = R.pathOr('0', ['data', 'organization', 'id'], response) as string
  }

  if (!me) return false

  await getRecord(USER_PREFERENCES_RECORD, utils.convertToInt(me))
  const myNotificationPref = myNotificationPreferenceSelector(store.getState())
  listenForNotifications(myNotificationPref)

  await getRecord(ORGANIZATIONS_RECORD, orgId)
  const orgType = myOrganizationPropSelector('type')(store.getState(), {})
  const role = ORG_TYPE_TO_ROLE_MAP[orgType as string]
  store.dispatch(setApplicationProperties({ role }))
  const user = meSelector(store.getState())
  const org = records.entitiesSelectors.entityByIdSelector(
    ORGANIZATIONS_RECORD,
    myOrganizationIdSelector,
  )(store.getState())
  const userProperties = getUserProperties(user, org)
  trackSessionAnalytics('started_session', userProperties)
  setUserProperties(userProperties)
  /* istanbul ignore next */
  Sentry.withScope((scope) => {
    /* istanbul ignore next */
    scope.setUser(userProperties)
  })
  return role
}
