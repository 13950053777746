import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'
import * as Sentry from '@sentry/react'

const isProd = () =>
  process.env.NODE_ENV === 'production' &&
  utils.host.getHostname() !== 'localhost'

export default function initiateSentry() {
  if (isProd()) {
    Sentry.init({
      dsn: 'https://78f90b3171c277a495282112217773b4@o4505121413660672.ingest.us.sentry.io/4507612061630464',
      environment: utils.host.getHostname(),
      ignoreErrors: ['lastpass', 'ResizeObserver'],
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      tracesSampleRate:
        utils.host.isInDevMode || utils.host.isRunningOnDev ? 1.0 : 0.25,
    })
  }
}

export const logComponentDidCatch = (error: any, errorInfo: any) => {
  if (isProd()) {
    Sentry.withScope((scope) => {
      R.forEachObjIndexed(
        (value, key) => scope.setExtra(key as string, value),
        errorInfo,
      )
      Sentry.captureException(error)
    })
  }
}
