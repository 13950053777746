import React, { useContext } from 'react'
import { RouterContext } from 'domains/application/screens/AppScreen'
import { RouteComponentProps } from 'react-router'
import { View, Image, Text, Button } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import { utils } from '@ims/1edtech-frontend-common'

interface ISubtitleProps {
  children: any
}
function Subtitle(props: ISubtitleProps) {
  return (
    <Text
      fontSize={[18, 22]}
      lineHeight={['28px', '32px']}
      fontWeight={500}
      textAlign="center"
    >
      {props.children}
    </Text>
  )
}

interface IProps {
  noScreenWrapper?: boolean
  title?: string
}

export default function GenericError(props: IProps) {
  const router = useContext(RouterContext) as RouteComponentProps

  const onGoBack = () => {
    if (props.noScreenWrapper) {
      window.location.href = '/'
    } else {
      router.history.goBack()
    }
  }

  const content = (
    <View flexible="column-center">
      <View variant="paper" width={['auto', 600, 900]} my={[3, 4]} mb={3}>
        <View p={[16, 48]} flexible="column-center">
          <Text
            fontSize={54}
            lineHeight="72px"
            fontStyle="italic"
            fontWeight={100}
            textAlign="center"
            data-test="generic-error-title"
          >
            Oops!
          </Text>

          <Image
            src="/images/1EdTechLogo.svg"
            width="auto"
            maxWidth={[200, 300]}
            maxHeight={[200, 300]}
            height="auto"
            m={[3, 4, 5]}
          />

          <View data-test="generic-error-subtitle">
            {utils.hasValue(props.title) && (
              <View mb={4}>
                <Subtitle>{props.title}</Subtitle>
              </View>
            )}
            <Subtitle>The page you are looking for cannot be found!</Subtitle>
          </View>

          <Text
            mt={2}
            fontSize={16}
            lineHeight="28px"
            fontWeight={500}
            textAlign="center"
            data-test="generic-error-subtext"
          >
            (You can report the error by submitting an email to{' '}
            <a href="mailto:support@1edtech.org">support@1edtech.org</a>)
          </Text>

          <Button mt={[3, 4]} variant="complete" onClick={onGoBack}>
            {props.noScreenWrapper ? 'Home' : 'Go Back'}
          </Button>
        </View>
      </View>
    </View>
  )

  if (props.noScreenWrapper) {
    return content
  }

  return (
    <View variant="screen">
      <DocumentTitle title="Oops!" />
      <BaseBreadCrumbs crumbs={[{ name: 'Oops!' }]} />
      {content}
    </View>
  )
}
